/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  left: 100%;
  top: 0;
  position: absolute;
  background-color: #f9f9f9;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  width: 110%;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;    
}

/* Links inside the dropdown */
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.responses {
  border: 1px solid #cccccc;
  background-color: whitesmoke;
  bottom: 45px;
  left: 32%;
  position: absolute;
  background-color: #f9f9f9;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  height: 50%;
  width: 25%;
}

.response-content div:hover {
  background-color: #f1f1f1;
  border-radius: 16px;
}

.responses-matched {
  border: 1px solid #cccccc;
  background-color: white;
  bottom: 45px;
  right: 31%;
  position: absolute;
  margin-top: 0;
  min-width: 160px;
  z-index: 1;
  border-radius: 8px;
  height: 50%;
  width: 25%;
}

.mainInput:focus {
  border: 0 none #fff;
  outline: none;
  box-shadow: none;
}

.mainInput::placeholder {
  font-size: 14px;
  align-self: flex-end;
  flex-direction: column;
  color: #000;
}

.inputAgent::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666666;
  border: 'none';
  opacity: 1;
}

.inputAgent::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666666;
  border: none;
  opacity: 1;
}

.filterDropdown {
  position: absolute;
  /* top: 70px; */
  max-height: 80vh;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 15;
  /* width:  21%; */
  /* margin-left: 70px; */
}

.repliesSidebarWhatsApp {
  /* position: absolute;
  top: 60;
  right: 0; */
  /* height: 100%; */
  background: var(--whites-white-01, #fff);
  box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.25);
  /* width: min(100%, 483px); */
  width: 360px;
  z-index: 1;

  /* overflow-y: auto; */
  /* scrollbar-width: thin; */
}

.repliesSidebarHeaderActionItems {
  width: min(100%, 483px);
}

.resolveDiv:hover {
}

.comment-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color-border-medium, #ccc);
  padding: 7px 8px;
  font-size: 14px;
  gap: 8px;
  width: 95% !important;
}

.comment-field-2 {
  width: 95% !important;
  height: 40px !important;
  outline: none !important;
  overflow-y: auto;
  resize: none;
  scrollbar-width: thin;
  appearance: none;
  border: none;
  background: none;
  outline: none;
}

.comment-field {
  width: 95% !important;
  height: 40px !important;
  outline: none !important;
  overflow-y: auto;
  resize: none;
  scrollbar-width: thin;
  appearance: none;
  border: none;
  background: none;
  outline: none;
}

.comment-field:focus {
  height: 8rem !important;
}

.comment-field::placeholder {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: #cccccc;
  align-self: flex-end;
  margin: 10px !important;
}

.conversation:hover {
  border: 1px solid black;
}

.emojiMenu {
  position: absolute;
  bottom: 46px;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  width: 20vw;

  margin-left: 60px;
}

.onhoverattr {
  /* background-color: white; */
}

.onhoverattr:hover {
  background-color: #e6e6e6;
  border-radius: 4px;
}

.habuild-timer {
  flex: 1;
  margin: 0 10px;
  margin-left: auto;
}

.addNewChatDropDown {
  position: absolute;
  /* top: 70px; */
  max-height: 80vh;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 12px;
  /* width:  21%; */
  /* margin-left: 70px; */
}

.sendTemplateModal {
  min-width: 60vw !important;
  border-radius: 22px !important;
  z-index: 1;
}

.shortcutModal {
  top: 10rem;
  border-radius: 16px;
  z-index: 1 !important;
}

.selectedIndex {
  background-color: #e6e6e6;
  border-radius: 4px;
}

.background-modal {
  opacity: 1;
  /* Initial opacity */
}

.background-modal.modal.show {
  color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 1;
}

.quickReplyModal {
  max-width: 35vw !important;

  border-radius: 22px !important;
  z-index: 1;
}

.typing-indicator {
  position: absolute;
  bottom: 50px;
  left: 20px;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
}

.triggerFlows {
  border: 1px solid #cccccc;
  background-color: #ffffff;
  /* bottom: 45px; */
  bottom: 60px;
  right: 6%;
  position: absolute;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  height: 50%;
  width: 25%;
}

.workflow-item {
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #e6e6e6;
}

.workflow-item:last-child {
  border-bottom: none;
}

.workflow-item:hover {
  background-color: #f5f5f5;
}

.filterToggleHover {
  border: 1px solid transparent;
}

.filterToggleHover:hover {
  border: 1px solid #cccccc;
  background-color: #f0f0f0;
}

.chatItemHover {
  border-radius: 10px;
  border: 1px solid transparent;
}

.chatItemHover:hover {
  border: 1px solid #cccccc;
  /* background-color: red; */
}

.comment-field-footer {
  border: none;
  width: '100%';
  font-size: '16px';
  /* fontWeight: '500',
      borderRadius: '8px',
      border: '1px solid grey',
      padding: '8px',
      */
  min-height: '50px';
  height: '100%';
}

.comment-field-footer:focus {
  border: none;
}

.comment-field-footer:hover {
  border: none;
}

.message-container .reply-icon {
  opacity: 0; /* Hide the icon by default */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.message-container:hover .reply-icon {
  opacity: 1; /* Show the icon on hover */
}

.scroll-to-bottom-button {
  bottom: 20%;
  right: 26%;
}

@media (max-width: 1280px) {
  .scroll-to-bottom-button {
    bottom: 22%;
    right: 30%;
  }
}

@media (max-width: 768px) {
  .scroll-to-bottom-button {
    display: none;
  }
}

@media (max-width: 480px) {
  .scroll-to-bottom-button {
    display: none;
  }
}
