@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.partner_main_container {
    height: 100dvh;
    width: 100%;
    @extend .Row;
    // background-color: cornflowerblue;

    &__sidebar {
        width: f.rem(250);
        height: 100%;
        background-color: colors.$white01;
        padding-block: f.rem(30);
        // padding-inline: f.rem(20);
        @extend .Column;
        justify-content: flex-start;
    }

    &__body {
        width: calc(100% - f.rem(250));
        height: 100%;
        // background-color: darkcyan;
    }
}

.psidebar_container {
    @extend .Row;
    justify-content: flex-start;
    padding-block: f.rem(10);
    padding-inline: f.rem(20);
    // background-color: blueviolet;
    width: 100%;
    cursor: pointer;
    
    &__image {
        height: 24;
        width: f.rem(24);
        object-fit: contain;
        margin-inline-end: f.rem(10);
    }

    &__text {
        font-size: f.rem(16);
        font-weight: 400;
    }
}

.psidebar_container:hover {
    background-color: colors.$primary01;
}

.reseller_container {
    height: 100dvh;
    width: 100%;
    @extend .Column;
    justify-content: center;
    background-color: colors.$white02;
    // padding-block: f.rem(30);
    // padding-inline: f.rem(32);
}

.reseller_login_container {
    height: min(f.rem(640), 80vh);
    width: f.rem(450);
    background-color: colors.$white01;
    border: 1px solid colors.$borderwhite;
    border-radius: f.rem(12);
    padding-block: f.rem(30);
    padding-inline: f.rem(34);
    @extend .Column;
    // justify-content: flex-start;
    overflow-y: auto;

    h3 {
        font-weight: 700;
        width: 100%;
    }

    &__form_div {
        width: 100%;
        margin-block: f.rem(30);
        flex: 1;

        .forgot_password_div {
            @extend .Row;
            width: 100%;
            justify-content: flex-end;
            margin-block-start: f.rem(16);

            .password_text_style {
                font-size: f.rem(16);
                font-weight: 600;
                color: colors.$link01;
                cursor: pointer;
            }
        }
    }
}

.form_heading_style {
    font-size: f.rem(14);
    font-weight: 600;
    color: colors.$black01;
}

.form_body_style {
    font-size: f.rem(16);
    font-weight: 400;
    color: colors.$black01;
}

.reseller_dashboard_container {
    @extend .Column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding-block: f.rem(30);
    padding-inline: f.rem(32);

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            font-weight: 700;
            color: colors.$black01;
        }

        .logged_input {
            font-size: f.rem(14);
            // color: brown;
            border: 1px solid colors.$black04;
            border-radius: f.rem(12);
            padding-block: f.rem(7);
            padding-inline: f.rem(16);
            // background-color: chartreuse;

            ::placeholder {
                color: colors.$black04;
                opacity: 1;
            }

            ::-ms-input-placeholder {
                /* Edge 12-18 */
                color: colors.$black04;
            }

            .search_input {
                margin-inline-start: f.rem(6);
                appearance: none;
                border: none;
                background: none;
                padding: 0;
                outline: none;
                font-size: f.rem(14);
                color: colors.$black01;
                opacity: 1;
            }
        }
    }

    &__body_div {
        @extend .Column;
        width: 100%;
        flex: 1;
        overflow-y: hidden;
        overflow-x: auto;
        // background-color: brown;
        margin-block-start: f.rem(30);
    }
}