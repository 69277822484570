@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.otp_verify_modal {
    @extend .Column;
    height: 80vh;
    width: min(90vw, f.rem(432));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(10);

    @include b.breakpoint(large) {
        padding-inline: f.rem(20);
    }

    &__header {
        @extend .Row;
        width: 100%;
        padding-block: f.rem(10);
        border-bottom: 1px solid colors.$borderwhite;
        justify-content: flex-end;
        // padding-inline: f.rem(16);

        img {
            height: f.rem(24);
            width: f.rem(24);
            object-fit: contain;
            cursor: pointer;
        }
    }

    &__body {
        @extend .Column;
        width: 100%;
        margin-block: f.rem(10);
        padding-block: f.rem(10);
        flex: 1;
        overflow-y: auto;
        // background-color: aqua;
        justify-content: flex-start;
        align-items: flex-start;

        .enter_otp_text {
            color: colors.$black01;
            width: 100%;
            margin-block-end: f.rem(16);
            font-weight: bold;
        }

        .otp_sent_text {
            color: colors.$greys04;
            width: 100%;
            margin-block: f.rem(16);
        }

        .incorrect_otp_text {
            color: colors.$failure03;
            width: 100%;
            text-align: center;
            margin-block: f.rem(24);
        }
    }
}