@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.email_label_modal {
    @extend .Column;
    height: 80vh;
    width: min( 90vw , f.rem(420));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(10);

    @include b.breakpoint(large){
        padding-inline: f.rem(20);
    }

    &__header {
        @extend .Row;
        width: 100%;
        padding-block: f.rem(10);
        border-bottom: 1px solid colors.$borderwhite;

        h3 {
            color: colors.$black01;
        }

        img {
            height: f.rem(24);
            width: f.rem(24);
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.email_modal_body {
    // background-color: aquamarine;
    border-radius: f.rem(16);
}